<!-- 待缴费 -->
<template>
  <div id="electricityFee">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          :title="$t('tuition.title')"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div id="listLoading">
      <van-loading
        v-if="loading"
        type="spinner"
      />
    </div>
    <div class="outboxstyle">
      <!-- 姓名栏 -->
      <div class="boxstyle">
        <div class="stu-name">
          <img src="@/assets/icon/person.png">
          <span>{{ userName }}</span>
        </div>
        <div class="stu-num">{{ userId }}</div>
        <!-- <p style="line-height:50px">{{ $t('main.selected') }}<span style="color:#3BA4F2">&nbsp;&nbsp;{{ count }}&nbsp;&nbsp;</span>{{ $t('pay.total') }}<span style="float:right;color:red">¥&nbsp;{{ total }}</span></p> -->
      </div>
      <div v-for="(item, index) in paymentList" :key="index">
        <van-cell-group :class="[item.show?'group':'group2']" @click="choose(item)">
          <img :src="item.show==true?imageL[1] : imageL[2]" class="line">
          <!-- <div class="total">
            <img :src="imgList[0]">
            {{ item.projectName }}
          </div> -->
          <span class="item-title">{{ item.proName }}</span>
          <span class="item-day">（学年：{{ item.schoolYear }}）</span>
          <van-cell title="缴费权重" :value="item.weight+'级'" />
          <van-cell :title="$t('pay.tobe-pay')" :value="'¥'+fenToYuan(item.actualReceivable)" />
          <van-cell title="减免金额" :value="'¥'+fenToYuan(item.deductionAmount)" />
          <van-cell :title="$t('pay.unpaid-amount')" :value="'¥'+fenToYuan( item.actualReceivable - item.deductionAmount - (item.sumReceipts - item.receiptsAmountGreen) )" />
          <van-cell :title="$t('pay.full-payment')">
            <van-switch v-model="item.fullPay" size="24px" :class="{'can-click':item.partPayment}" :disabled="item.partPayment" @click="check(item, index)" />
          </van-cell>
          <!-- <van-cell id="totalpay" title="缴费金额" :value="'¥'+fenToYuan(item.payNowStr)" /> -->
          <van-cell :title="$t('pay.payment-amount')">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #default>
              <span v-if="item.fullPay" style="color:#3BA4F2">¥{{ fenToYuan( item.actualReceivable - item.deductionAmount - (item.sumReceipts - item.receiptsAmountGreen) ) }}</span>
              <span v-if="!item.fullPay" style="color:#3BA4F2">¥{{ item.payNowStr }}</span>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <!-- 底部固定栏 -->
    <div v-show="!showBtn" class="bottom">
      <div class="bot-cost">
        <span>{{ $t('main.money') }}：</span>
        <span class="num">￥{{ total }}</span>
      </div>
      <div style="position: relative">
        <van-button type="info" size="large" :loading="nextLoading" class="bottombutton" @click="nextStep">{{ $t('main.next') }}</van-button>
        <van-tag v-if="count" round type="danger" class="tag">{{ count }}</van-tag>
      </div>
    </div>
    <div v-show="showBtn" class="bottom bottom2">
      <van-button type="info" :disabled="comfigFlag" style="width:100%;" @click="nextStepGoToGreen">确认提交</van-button>
    </div>
    <van-dialog v-model="show" title="修改缴费金额" show-cancel-button :before-close="chargeBtn">
      <!-- 允许输入正整数，调起纯数字键盘 -->
      <van-field v-model="money" style="width: 90%;margin: 20px;border: 1px solid #eee;" type="number" />
    </van-dialog>
    <!-- 回到顶部按钮 -->
    <v-top />
  </div>
</template>

<script>
// import { pageListReiForm } from '@/api/reiform'
import { getTuitionAndDormListForYingxin, createOrder, nextStepGoToGreen } from '@/api/tuitionAndDorm'
// import { getTuitionAndDormListForYingxin } from '@/api/tuitionAndDorm'
import { findUserInfoByAccountNum } from '@/api/password'
import { getProjectVoById } from '@/api/projectApply'
// import { getWaitingPayList, submitNextStep } from '@/api/waitingPay'
import { fenToYuan } from '@/utils/commonUtils'
// import moment from 'moment/moment'
import { Dialog } from 'vant'
import { Toast } from 'vant'
import { Tag } from 'vant'
export default {
  name: 'Project',
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Tag.name]: Tag
  },
  data() {
    return {
      value: '',
      showPicker: false,
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      conheight: {
        height: '',
        width: '100%',
        overflow: 'auto',
        // background: "#EFF6FF"
        background: 'transparent'
      },
      checked: false,
      imgList: [
        require('../../assets/newImages/icon4.png'),
        require('../../assets/newImages/icon6.png'),
        require('../../assets/newImages/orderList4.png')

      ],
      imageL: [
        require('../../assets/newImages/home_icon6.png'),
        require('../../assets/newImages/right.png'),
        require('../../assets/newImages/rightbefore.png')
      ],
      paymentList: [
        // {
        //   schoolYear: '', // 学年
        //   actualReceivable: '', // 应缴金额
        //   sumReceipts: '', // 已缴金额
        //   fullPay: false, // 是否全款，true为全款，false为手动输入
        //   payNowStr: '' // 手输入金额
        // }
      ], // 学费住宿费列表详情
      total: 0, // 选中项目的总金额
      count: 0, // 选中的缴费项目个数
      show: false, // 自定义价格弹窗判断状态，false关闭弹窗（默认），true打开弹窗
      money: '', // 自定义价格弹窗中手动输入值
      moneyindex: '', // 缴费项目索引值，string(eg:0、1、2、)
      nextLoading: false, // 下一步按钮加载状态判断，false关闭加载状态（默认），true打开加载状态
      sectionAmount: 0,
      allAmount: 0,
      loading: false, // 加载提示状态判断，false关闭提示（默认），true打开提示
      userName: '', // 用户名
      amountLimit: 0, // 缴费最小金额
      userId: '', // 用户学号
      finalTotal: 0, // 所以项目允许缴费的最大值
      routeParams: this.$route.params,
      showBtn: '', // flase(默认),支付模式，true免支付模式
      jsonObj: '', //  token返回的+dataSource+schoolCode
      projectId: '', // 项目id
      comfigFlag: false, // 确认按钮
      returnUrl: ''
    }
  },
  watch: {
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  created() {
  },
  mounted() {
    var newStr = JSON.parse(this.routeParams.jsonData)
    newStr.dataSource = this.$store.state.settings.dataSource
    newStr.schoolCode = window.localStorage.getItem('schoolcode')
    this.jsonObj = newStr
    this.projectId = this.jsonObj.projectId
    this.getWaitingPayList(this.routeParams.jsonData, JSON.parse(this.routeParams.jsonData).idserial, JSON.parse(this.routeParams.jsonData).projectId)
    this.getProjectLimitAmount(this.projectId)
    this.returnUrl = this.routeParams.returnUrl
  },
  methods: {
    // 赋值最底缴费金额
    getProjectLimitAmount(projectId) {
      getProjectVoById(projectId).then(response => {
        console.log('根据项目id获取项目最低缴费金额：' + response.data.payLimitStr)
        if (response.data.payLimitStr !== null && response.data.payLimitStr !== '' && response.data.payLimitStr !== undefined) {
          this.amountLimit = response.data.payLimitStr
        }
      })
    },
    // 免缴费确认按钮
    nextStepGoToGreen() {
      nextStepGoToGreen(this.paymentList).then(response => {
        Toast.success('确认提交完毕，请勿重复确认提交')
        this.comfigFlag = !this.comfigFlag
      })
    },
    /**
     * choose 选则缴费项目
     * @param {obj} obj
     */
    choose(obj) {
      if (obj.actualReceivable - obj.sumReceipts < 0) {
        Toast.fail('请选择缴费金额大于0的项目')
        return false
      }
      obj.show = !obj.show
      this.calculationData()
      return obj
    },
    /**
     * check 全额缴费部分缴费切换事件
     * @param {obj} obj 缴费项目对象
     * @param {str} index 缴费项目索引
     */
    check(obj, index) {
      // 阻止事件穿透
      event.stopPropagation()
      if (obj.fullPay === true) {
        this.show = true
        // 获取当前数据的未缴金额
        this.money = ''
        // 获取当前数据的index
        this.moneyindex = index
        this.calculationData()
      } else {
        obj.fullPay === true
        this.$set(this.paymentList[this.moneyindex], 'fullPay', true)
        // console.log(obj.fullPay)
        obj.payNowStr = obj.unpaidamt
        this.calculationData()
      }
    },
    /**
     * chargeBtn 修改全额缴费弹窗
     * @param {string} action 确认：confirm 取消：cancel
     * @param {fun} done
     */
    chargeBtn(action, done) {
      if (action === 'cancel') { // 取消按钮
        this.paymentList[this.moneyindex].fullPay = true
        this.calculationData()
        done()
      } else if (action === 'confirm') { // 确定按钮
        // if (this.money < 100) {
        //   Toast.fail(this.$t('msg.moneyLimit100'))
        //   done(false)
        //   return false
        // }
        // console.log(this.paymentList[this.moneyindex].actualReceivable)
        if (this.paymentList[this.moneyindex].actualReceivable > parseFloat(this.amountLimit) * 100) {
          if (this.money < parseFloat(this.amountLimit)) {
            Toast.fail('请输入高于缴费最底金额' + this.amountLimit + '元')
            return false
          }
        }
        if (this.money * 100 >= this.paymentList[this.moneyindex].finalPay) {
          Toast.fail(this.$t('msg.amountOver'))
          done(false)
          return false
        }
        if (this.money === '') {
          Toast.fail(this.$t('msg.partPaid'))
          done(false)
          return false
        }
        // 向后端传值并关闭dialog弹出框
        this.paymentList[this.moneyindex].payNowStr = this.money
        this.calculationData()
        this.$set(this.paymentList[this.moneyindex], 'fullPay', false)
        // this.paymentList[this.moneyindex].fullPay = false
        this.show = false
        done()
      }
    },
    // 获取数据
    getWaitingPayList(proId, num, jsonObj) {
      // 获取用户名和学号
      const accountNum = this.$store.state.user.accountNum || localStorage.getItem('accountNum') || this.$route.query.accountNum
      const schoolCode = window.localStorage.getItem('schoolcode')
      const dataSource = this.$store.state.settings.dataSource
      findUserInfoByAccountNum(accountNum, schoolCode, dataSource).then(res => {
        this.userName = res.data.name
        this.userId = res.data.idserial
      })
      console.log(jsonObj)
      // 获取缴费信息列表
      getTuitionAndDormListForYingxin(this.jsonObj).then(res => {
        // console.log(res.data)
        this.paymentList = res.data
        // console.log(this.paymentList)
        var maxPay = 0
        this.paymentList.forEach(item => {
          this.$set(item, 'fullPay', true)
          this.$set(item, 'needPay', '0.00')
          this.$set(item, 'cutPay', item.actualReceivable - item.deductionAmount - (item.sumReceipts - item.receiptsAmountGreen))
          maxPay += item.cutPay
          this.$set(item, 'finalPay', item.actualReceivable - item.sumReceipts)
          if (item.partPayment === '1') {
            item.partPayment = false
          } else {
            item.partPayment = true
          }
        })
        this.maxPay = maxPay
        if (parseFloat(maxPay) <= 0) {
          this.showBtn = true
        }
        for (var item of this.paymentList) {
          this.choose(item)
        }
      }).catch(error => {
        Toast.fail(error.message)
      })
    },
    // 下一步按钮触发事件
    nextStep() {
      // this.finalTotal = 0
      // for (var val of this.paymentList) {
      //   // console.log(val.actualReceivable)
      //   this.finalTotal += parseFloat(val.actualReceivable)
      // }
      // if (this.finalTotal > 1000) {
      //   if (this.total < 10) {
      //     Toast.fail('总缴费金额不得低于10元')
      //     return false
      //   }
      // }
      const payList = []
      // 把选中的缴费模块塞进payList数组中
      for (let i = 0; i < this.paymentList.length; i++) {
        if (this.paymentList[i].show === true) {
          payList.push(this.paymentList[i])
        }
      }
      const paymentList = JSON.parse(JSON.stringify(payList))
      for (let j = 0; j < paymentList.length; j++) {
        if (paymentList[j].payNowStr >= paymentList[j].unpaidamt) {
          paymentList[j].payNowStr = null
        }
      }
      // 缴费项目校验
      var flag = this.checkRules(paymentList)
      if (flag === false) {
        return false
      }
      this.nextLoading = true
      payList.forEach(item => {
        this.$set(item, 'projectId', this.projectId)
        this.$set(item, 'returnUrl', this.returnUrl)
      })
      // 提交表单
      // submitNextStep(paymentList).then(response => {
      //   var order = response.data.PayOrderTrade
      //   this.$router.push({ name: 'person', query: { projectId: this.paymentList[0].projectId, amount: this.total, order: order, orderId: order.id }})
      //   this.nextLoading = false
      // }).catch(err => {
      //   Toast.fail(err.message)
      //   this.nextLoading = false
      // })

      // 权重校验
      var chooseList = []
      var idList = []
      for (var item of this.paymentList) {
        if (item.show === true) {
          chooseList.push(item)
        }
        idList.push(item.weight)
      }
      var smallWeight = chooseList[0].weight // smallWeight选中的最底权重值
      for (var item2 of chooseList) { // 遍历出选中的项目中权重最小的值
        if (smallWeight > item2.weight) {
          smallWeight = item2.weight
        }
      }
      // console.log('最小权重', smallWeight)
      // console.log('idlist', idList)
      var smallWeightNum = 0
      for (var num of this.paymentList) {
        if (num.weight === smallWeight) {
          smallWeightNum = 1 + smallWeightNum
        }
      }
      for (var i of this.paymentList) {
        if (i.weight > smallWeight) { // 找出比选中最小权重大的项
          if (i.show === true) {
            if (i.fullPay === false) {
              Toast.fail('存在缴费项目的缴费权重高于此项目的缴费信息，请优先支付权重高的缴费项目')
              this.nextLoading = false
              return
            }
          } else {
            Toast.fail('存在缴费项目的缴费权重高于此项目的缴费信息，请优先支付权重高的缴费项目')
            this.nextLoading = false
            return
          }
        } else if (i.weight === smallWeight) { // 找出比选中最小权重等于的项
          if (smallWeightNum > 1) { // 相同最小权重的个数大于1
            if (i.show === true) { // 选中
              if (i.fullPay === false) { // fullpay为false
                Toast.fail('相同缴费权重的缴费项目必须同时支付')
                this.nextLoading = false
                return
              }
            } else { // 未选中
              Toast.fail('相同缴费权重的缴费项目必须同时支付')
              this.nextLoading = false
              return
            }
          }
        }
      }
      this.nextLoading = false
      for (var last = 0; last < payList.length; last++) {
        if ((payList[last].actualReceivable - payList[last].deductionAmount - (payList[last].sumReceipts - payList[last].receiptsAmountGreen)) === 0) {
          payList.splice(last, 1)
        }
      }
      createOrder(payList).then(response => {
        var order = response.data.PayOrderTrade
        this.$router.push({ name: 'person', query: { projectId: payList[0].projectId, amount: this.amount, order: order, orderId: order.id }})
        this.nextLoading = false
      }).catch(() => {
        this.nextLoading = false
      })
    },
    /**
     * 下一步提交前校验
     * @param {Array} payList 选中的项目数组
     * @returns {Boolean}
     */
    checkRules(payList) {
      var errorFlag = true
      var list = payList
      // 未选择缴费项目判断提示
      if (list.length < 1) {
        Toast.fail(this.$t('msg.selectPaid'))
        return false
      }
      // 如果部分缴费 判断是否输入金额为空
      list.forEach(item => {
        if (item.fullPay === false) {
          if (item.payNowStr === null || item.payNowStr === '' || item.payNowStr === undefined) {
            errorFlag = false
          }
        }
      })
      // if (parseFloat(this.total) < parseFloat(this.amountLimit)) {
      //   Toast.fail('请输入高于缴费最底金额' + this.amountLimit + '元')
      //   return false
      // }
      if (errorFlag === false) {
        Toast.fail(this.$t('msg.partPaid'))
        return false
      }
      return true
    },
    // 返回
    onClickLeft() {
      // this.$router.push({ path: '/home' })
      this.$router.go(-1)
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/person'
      })
    },
    // 金钱转换
    fenToYuan(val) {
      return fenToYuan(val)
    },
    // 计算选中的数据
    calculationData() {
      this.total = 0
      this.count = 0
      this.allAmount = 0
      this.sectionAmount = 0
      for (let i = 0; i < this.paymentList.length; i++) {
        const allPay = []
        const sectionPay = []
        if (this.paymentList[i].show === true) {
          if (this.paymentList[i].fullPay === false) {
            sectionPay.push(this.paymentList[i])
            for (let j = 0; j < sectionPay.length; j++) {
              this.sectionAmount += Number(sectionPay[j].payNowStr)
            }
          } else {
            allPay.push(this.paymentList[i])
            for (let k = 0; k < allPay.length; k++) {
              this.allAmount += Number(allPay[k].actualReceivable - allPay[k].deductionAmount - (allPay[k].sumReceipts - allPay[k].receiptsAmountGreen))
            }
          }
          this.count += 1
        }
      }
      this.allAmount = fenToYuan(this.allAmount)
      this.total = (Number(this.sectionAmount) + Number(this.allAmount)).toFixed(2)
    }
  }
}
</script>
<style scoped lang="scss">
#electricityFee {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 86px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .van-switch {
    font-size: 24px;
    height: 22px;
    line-height: 24px;
  }
  .van-cell__value {
    height: 24px;
  }
}
.outboxstyle {
  // padding: 0px 10px 10px 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  font-size:16px;
  height:50px;
  background:#fff;
  border-radius:10px 10px 0px 0px;
  padding:0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .stu-name{
    display: flex;
    align-items: center;
    span{
      margin-left: 5px;
    }
  }
  .stu-num{
    color: #747474;
  }
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  padding-left: 20px;
  height: 80px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bot-cost{
    font-size: 14px;
    .num{
      font-size: 20px;
      color: #F86262;
    }
  }
  .bottombutton{
    margin: 20px;
    width: 100px;
    height: 40px;
  }
  .tag{
    position: absolute;
    right: 15px;
    top: 10px;
  }
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
.group {
  padding: 15px 20px 20px 20px;
  border-radius: 10px;
  margin: 20px 20px;
  border:1px solid #3BA4F2
}
.group2 {
  padding: 15px 20px 20px 20px;
  border-radius: 10px;
  margin: 20px 20px;
  border:1px solid #eeeeee
}
.line{
  width:40px;
  position:absolute;
  right:0;
  top:-0;
  z-Index:2;
}
.total{
  font-size:18px;
  line-height:70px;
  font-weight:700;
  display: flex;
  align-items: center;
  img{
    align-items: center;
    margin:0 20px 0 10px;
    width:40px;
  }
}
</style>
<style lang="scss">
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
.van-switch__node {
  width: 20px !important;
  height: 20px !important;
  left: 2px;
}
#listLoading .van-loading{
    position: fixed !important;
    color: #c8c9cc !important;
    font-size: 0;
    vertical-align: middle;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    background: #333333;
    opacity: 0.8;
    border-radius: 10px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
#listLoading .van-loading__spinner {
    color: rgb(255, 255, 255);
    width: 40px !important;
    height: 40px !important;
    margin: 20px !important;
}
.item-title{
  color: #2E2E2E;
  font-size: 16px;
  margin-left: 15px;
}
.item-day{
  color: #747474;
  font-size: 14px;
}
.msg-tip{
  padding: 0 10px;
}
.can-click{
  pointer-events:none
}
</style>
